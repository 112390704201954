import { set } from "animejs";
import { useEffect,useState,useCallback, useMemo, useRef} from "react"
import { anime } from "react-anime"

let in_motion = false;
let rmode = false;
let scowl = 0;

class ArrowObj{
    // Maybe i can use this to generate a new arrow element in the dom

    constructor(){
        this.type = 'p';
        this.text = '⧐';
        this.angle = 0;
        this.turns = 0; // multiplied to 360 to account for a wind
        this.arrowQuadrant = 1;
    }

    changeAngle(newangle){
        // This angle is just how the math equation gives me. For continuous turning you to set a 
        // Piecewise function. If angle is negative, use the oposite
        let quadrant = 4
        
        const AngleToBeUsed = newangle[0]>0? newangle[0]:newangle[1]

        if (AngleToBeUsed >= 0 && AngleToBeUsed < 90){
            quadrant = 4
        }else if (AngleToBeUsed >= 90 && AngleToBeUsed < 180){
            quadrant = 3
        }else if (AngleToBeUsed >= 180 && AngleToBeUsed < 270){
            quadrant = 2
        }else if ( AngleToBeUsed >= 270 ){
            quadrant = 1
        }

        // change angletobe used when crossing the line
        if ((quadrant == 1 && this.arrowQuadrant == 4 )|| (quadrant == 4 && this.arrowQuadrant == 1)){
            if (quadrant == 1 && this.arrowQuadrant == 4 ){
                this.turns -= 360
            }else{
                this.turns += 360
            }
        }
        const outputAngle = AngleToBeUsed+this.turns
        this.arrowQuadrant = quadrant
        this.angle = outputAngle
        return outputAngle;
    }


}



function arrowBehavior(arrow,StatedArrow){
    
    
    function moved(e){
        if (!arrow.current){
            document.removeEventListener('mousemove',moved)
            return
        }
        const elemen = arrow.current.getBoundingClientRect()
        
        if (((e.clientX-elemen.x)**2 + (e.clientY-elemen.y)**2)**0.5 > 30 && ((e.clientX-elemen.x)**2 + (e.clientY-elemen.y)**2)**0.5 < 900 && !in_motion){

            in_motion = true;
            const angleCalculation = [Math.atan2((e.clientY-elemen.y),(e.clientX-elemen.x))*180/Math.PI,360+Math.atan2((e.clientY-elemen.y),(e.clientX-elemen.x))*180/Math.PI];

            anime({ targets:['.pointer'],
                    easing:'steps(5000)', 
                    keyframes:[ 
                        {   
                            rotate:StatedArrow.changeAngle(angleCalculation)-8,
                            duration: 100
                        },{
                            top:`${e.pageY}px`,
                            left:`${e.pageX}px`,
                            duration:600
                        }
                        
                    ],
                    update:anim=>{
                        if(anim.progress == 100)in_motion = false;
                    }

                })

        }

       

    }

    document.addEventListener('mousemove',moved)
}


function Home(){
    
    let [angle,changAngle] = useState(0)
    let arrow = useRef()
    
    let [armed,setArmed] = useState(false)
    let [move,setMove] = useState(false)
    const [countUp,changeCount] = useState(0)
    //Have 6 divs shoot off in random directions. Keep the sizes pixel perfect
    let [tvalue,ttrigger] = useState(0);
    if (tvalue == 0)ttrigger(1)

    const [output,unstateful] = useState(()=>{
        const arrowStateType = new ArrowObj()
        let direcitonVector = []
        let message = "⧐Still working on the home page. Feel free to explore the other tabs"
        message = message.split('')
        
        const x = message.map((content,ind)=>{
            if (content == '⧐') return (<p className="pointer"
                                            ref = {arrow}
                                            key = "pointer">
                                            {content}
                                        </p>)
            
            return <p 
            key = {ind} 
            className={`home-letter ${ind}${content}${ind}`}
            
            >
                {content}
            </p>
                
                
        
        })
    
        return [x,direcitonVector,arrowStateType];
    },[scowl]);
    
    


    useEffect(()=>{

        
        
        if (armed == true){
            arrowBehavior(arrow,output[2])
        }
    },[armed])
    
    // add the difference between angles

    
        
    useEffect(
        

        ()=>{
            

            const anma = anime.timeline({targets:['.pointer','.home-letter'],
            
            top:'136px',
            left:anime.stagger(12),
            easing:'steps(1)',
            position:'absolute'
           })


            
            anime({
                targets:['.pointer'],
                delay:1000,
                duration:2000,
                scale:1.3,
                left:`${12*69}px`,
                update:(anim)=>{
                    if(anim.progress == 100){
                        setArmed(true)
                    }
                },
                easing:'easeInOutBack'
            })
        },[])
    return <div id='home-canvas'>{output[0]}</div>
}

export default Home