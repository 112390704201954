
import React,{useRef,useEffect,useState, useCallback} from 'react';


let letty = null;
let letterhash = null;
let keyboardletters = null;
let tex = <p>ooops</p>;


26-40
20-32

function DisplayGuesses(props){
    
    const answer = props.list.map((content,identifier)=><div className="guess-item" id={`wordlebox${identifier}`} key={identifier}>{content}</div>);
    
    return answer;
}// Wordle main body

function validate(answer,solution,args){
    
    // creates a new set every time this is called. needs optimizing 

    //hashmaps are super useful for this. I will change it later to reduce the amount of loops
    let butts = document.querySelectorAll('.highlightable')

    let forGrey = [];
    let forGreen = [];
    let tmpobj = JSON.parse(JSON.stringify(args[1]));
    let listofElements = [];
    for (let [ind,i] of answer.entries()){
        let temp = document.getElementById(`wordlebox${ind+args[0]}`);
        listofElements[ind]=temp;
    }
    //get greens > get greys. If the 
    
    for (let [index,element] of answer.entries()){
        element = element.toUpperCase()
        
        if (listofElements[index].textContent == solution[index].toUpperCase()){
            // if the text of the item
            //mark this letter for green
            forGreen.push([index,element]);
            listofElements[index].style.backgroundColor ="Green";
            tmpobj[element]-=1
            if (tmpobj[element]<1){
                delete tmpobj[element];
                // skips if there is a grey in there
            }
        }else{
            // if the element and letter dont match but you see that the letter exists, 
            // mark the letter for greying

            if(tmpobj.hasOwnProperty(element)){

                if (tmpobj[element] > 0){

                    forGrey.push([index,element]);
                }
            }
            
        }

        //got all the green. Need to loop through the grey and delete letters in there
        
    }
    
    for(let [index,element] of forGrey){
        // if this letter has at least one letter slot remaining, grey it out. If not, skip
        if (tmpobj[element]>0){
            listofElements[index].style.backgroundColor="Gray";
            for(let [index,el] of butts.entries()){
                
                if (el.textContent == element){
                    el.style.backgroundColor = 'red';
                    console.log(el.style.backgroundColor ,el.current, el , "gray")
                    break;
                    
                }
            }
        
            
            
            tmpobj[element]-=1;
        }
    }
    
    
}



function Wordle(props){
    let [index,setIndex] = useState(0);
    let [continuity,setcontinuity] = useState(true);
    let [tempdata,settempdata] = useState([]);
    const [enable,changevals] = useState(()=>{
        letty = new Array(35).fill('');
        letterhash = new Object();
        keyboardletters = new Array(26).fill(" ");
    })

    
    function clickBehavior(e){
        
        if (!e.key.match(/[a-z]/i)){
            //exit if the input isnt a letter
            setcontinuity(!continuity);
            return;
        }
        if (index == letty.length){
            //exit if we are at the end after validating
            validate(letty.slice(index-7,index),props.solution,[index-7,letterhash]);
            return;
        }
        //console.log(index-1%7)
        if (e.key == 'Backspace'){
            // If you hit bac   kspace, if you are at index 8, dont do anything, go back
            
            if (tempdata.length > 0){
                letty[index-1] = '';
                setIndex(index-1);
                tempdata.pop();
                settempdata(tempdata);
                
                return;
            }
            setcontinuity(!continuity);
        }
    
        if (index > 0 && index%7 == 0){

            // If you reach the end of a line, and we havent given permission to proceed yet
            if (e.key == 'Enter'){
                validate(letty.slice(index-7,index),props.solution,[index-7,letterhash]);
                settempdata([])
                return;
                // if i hit enter, we skip this
            }
        }
        
        // if i hit enter, it allows for us to go to the next line. 
        // Every char i enter sets nextline to false by default

        if (e.key.length == 1 && tempdata.length != 7){
            setIndex(index+1)
            tempdata.push(0);

            settempdata(tempdata);
            letty[index] = e.key.toUpperCase();
            
        }else{
            setcontinuity(!continuity);
        }

        setcontinuity(!continuity);
    }
    

    useEffect(()=>{
        for(let i of props.solution.toUpperCase()){
            if(letterhash.hasOwnProperty(i)){
                letterhash[i]+=1;
            }else{
                letterhash[i] = 1;
            }
        }
    },[]);
    
    // This event listener changes values in the guess boxes. It sets up once per render?
    window.addEventListener('keydown',clickBehavior,{once:true});

    function Keyboard(props){
        let [angle,ch] = useState(()=>{
            for(let [index,_] of keyboardletters.entries()){
                keyboardletters[index] = String.fromCharCode(65+index);
                keyboardletters[26] = "Enter";
                keyboardletters[27] = "Backspace";
            }
            tex = keyboardletters.map((value,index)=>
            <div 
            onClick={()=>{
                console.log("click ", continuity, !continuity)
                clickBehavior({key:value}); 
                setcontinuity(!continuity)
                }
            } 
            className={`keyboard-button keyboard${index} highlightable`}
            key={`keyboard${index}`}>
                <p>{value}</p></div>);
    
        })
        
        
        

        return <div id="keyboard">{tex}</div>
    }
    
    return (<>
                <div id="wordle-body">
                <DisplayGuesses list = {letty} solution = {props.solution} solhash={letterhash}/>
                </div> 

                <Keyboard/>
            </>
            );
}

export default Wordle;