import React,{useCallback,useState,useEffect} from "react";
import aboutThumbnail from './css/AboutThumbnail.png';

function About(props){

    // Image and a floating paragraph text
    let present = <div id="about-container"><img src={aboutThumbnail} width="10%" ></img>
    <p>My name is Emeka Ariwodo. I graduated from The University of Houston with a B.S in Mechanical Engineering and have since joined the Equipment Team at Q-Cells, a solar manufacturer.</p>
    <p>At the same time, I have used coding as a tool for efficiently executing tasks and storing data. Over time I have gained experience in different languages and frameworks, and developed a strong affinity for computational problem solving.</p>
    <p>I am a life long learner of all technologies.</p>
    </div>

    



    return present
}
export default About