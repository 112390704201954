import {useState,useEffect,useRef, useCallback } from "react";
import thumbnail from './css/Resumethmbnl.png'
import {anime} from "react-anime";

const TheaterMode = (ref,img) =>{
                        
    let refClassName = ref.current.className;
    
    anime({ targets:"#"+ref.current.id, scale:[0.7],
            duration:200,
            left:'0px',
            translateY:`${20}px`,
            translateX:`${-1200*0.2}px`,
            easing:'easeInOutBack',
            duration:500
        })
        
}

function ReturnToThumbnail(ref,initialsize,img){ 
        anime({targets: "#"+ref.current.id,scale:[initialsize,0.1], 
        left: `${-1200*0.4}px`,
        translateY:`${-20}px`,
        duration:300,
        easing:'easeInOutQuad',
        top:'35%'})
    }
    
function Resume(props){
    // Create the image
    const tempimage = new Image()
    tempimage.src = thumbnail
    let mounted = false;
    
    return ResumeLoaded(props,tempimage)
}
function ResumeLoaded(props,image){


    let [resumeState,changeRS] = useState({top:`40%`,left:image? '-800px':'-500px'})
    
    let refResume = useRef();
    let [r,rr] = useState(0)

    let [maximized,change] = useState(false);
    //calls this function and returns the element
    const resume = <div id="resume-container">
            <img 
            draggable={false}
            src={image.src} 
            ref={refResume}
            id="Resume-png"
            className="thumbnail-resume" 
            onClick={
                maximized? ()=>{ReturnToThumbnail(refResume,0.75,image);change(false)} : ()=>{TheaterMode(refResume,image);change(true)} 
                    }
            style={resumeState}>
            </img>
            
        </div>
    
    useEffect(()=>rr(1),[])
    //go through the resume list and rtt both using their refs
    if (refResume.current){
        
        //behavior for getting style and getting the accurate positions

    }
    useEffect(()=>{
        
        if (!maximized){
            ReturnToThumbnail(resume.props.children.ref,0.05,image);
        }
        else{
            TheaterMode(resume.props.children.ref);
        }

        
    },[r])

    return resume
}

export default Resume