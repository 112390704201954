import './App.css';
import 'bootstrap/dist/css/bootstrap.css'
import React ,{useState, useEffect, useRef, useCallback} from 'react';
// Firebase Initialization
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey:'AIzaSyDf7n10WdyeFHPdxrL5HaNYuwjNLPIllcI',
  storageBucket:'gs://ariapp-d5570.appspot.com',
  projectId:'ariapp-d5570'
};
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

async function getCities(db) {
  const citiesCol = collection(db, 'Functional');
  const citySnapshot = await getDocs(citiesCol);
  const cityList = citySnapshot.docs.map(doc => doc.data());
  return cityList;
}


// Begin firebase. Begin React app
import Notes, { killLines } from './Notes2.js';
import Wordle from './Wordle.js';
import Resume from './Resume.js';
import About from './About.js';
import Home from './Home.js';
import Brainerd from './Brainerd';

import {anime} from 'react-anime';
const wordleWord = 'zamboni';
let inMotion = false;
let sv;

function App() {
  
  let [height,changeHeight] = useState(0)

  let [backgroundSVG,u] = useState(()=>{
    sv = <svg style={{position:'fixed',zIndex:-1,left:0,top:0, width:'auto'}} id="visual-background" viewBox={`${100+5*Math.sin(height/60+.8)} 0 ${window.innerWidth*1.3} 1100`} height="1200" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1">
    <rect x="0" y="0" width={(window.innerWidth)*1.3} height="3000" fill="#487e91"></rect>
    <path d="M0 614L190 528L381 591L571 711L762 571L952 567L1143 455L1333 632L1524 721L1714 666L1905 602L2095 631L2286 575L2476 626L2667 535L2857 559L3048 518L3238 452L3429 445L3619 447L3810 510L4000 550L4000 0L3810 0L3619 0L3429 0L3238 0L3048 0L2857 0L2667 0L2476 0L2286 0L2095 0L1905 0L1714 0L1524 0L1333 0L1143 0L952 0L762 0L571 0L381 0L190 0L0 0Z" fill="#7cb0c2"></path>
    <path d="M0 413L190 474L381 385L571 586L762 365L952 503L1143 393L1333 560L1524 399L1714 500L1905 379L2095 460L2286 537L2476 586L2667 357L2857 502L3048 448L3238 503L3429 387L3619 387L3810 378L4000 405L4000 0L3810 0L3619 0L3429 0L3238 0L3048 0L2857 0L2667 0L2476 0L2286 0L2095 0L1905 0L1714 0L1524 0L1333 0L1143 0L952 0L762 0L571 0L381 0L190 0L0 0Z" fill="#72a4b5"></path>
    <path d="M0 303L190 383L381 432L571 349L762 364L952 309L1143 392L1333 433L1524 495L1714 464L1905 315L2095 521L2286 418L2476 422L2667 392L2857 439L3048 424L3238 538L3429 457L3619 457L3810 401L4000 443L4000 0L3810 0L3619 0L3429 0L3238 0L3048 0L2857 0L2667 0L2476 0L2286 0L2095 0L1905 0L1714 0L1524 0L1333 0L1143 0L952 0L762 0L571 0L381 0L190 0L0 0Z" fill="#6898a9"></path>
    <path d="M0 435L190 376L381 403L571 267L762 351L952 323L1143 477L1333 497L1524 392L1714 498L1905 325L2095 376L2286 337L2476 398L2667 497L2857 491L3048 471L3238 258L3429 320L3619 323L3810 414L4000 365L4000 0L3810 0L3619 0L3429 0L3238 0L3048 0L2857 0L2667 0L2476 0L2286 0L2095 0L1905 0L1714 0L1524 0L1333 0L1143 0L952 0L762 0L571 0L381 0L190 0L0 0Z" fill="#5f8c9c"></path>
    <path d="M0 258L190 260L381 213L571 314L762 380L952 259L1143 243L1333 367L1524 341L1714 347L1905 367L2095 379L2286 299L2476 388L2667 347L2857 227L3048 213L3238 210L3429 334L3619 332L3810 339L4000 295L4000 0L3810 0L3619 0L3429 0L3238 0L3048 0L2857 0L2667 0L2476 0L2286 0L2095 0L1905 0L1714 0L1524 0L1333 0L1143 0L952 0L762 0L571 0L381 0L190 0L0 0Z" fill="#558190"></path>
    <path d="M0 327L190 354L381 166L571 271L762 279L952 189L1143 163L1333 230L1524 176L1714 265L1905 310L2095 241L2286 277L2476 177L2667 330L2857 299L3048 188L3238 291L3429 321L3619 336L3810 347L4000 171L4000 0L3810 0L3619 0L3429 0L3238 0L3048 0L2857 0L2667 0L2476 0L2286 0L2095 0L1905 0L1714 0L1524 0L1333 0L1143 0L952 0L762 0L571 0L381 0L190 0L0 0Z" fill="#4c7684"></path>
    <path d="M0 211L190 121L381 149L571 179L762 276L952 174L1143 189L1333 226L1524 240L1714 236L1905 137L2095 228L2286 234L2476 239L2667 217L2857 252L3048 280L3238 215L3429 205L3619 269L3810 266L4000 281L4000 0L3810 0L3619 0L3429 0L3238 0L3048 0L2857 0L2667 0L2476 0L2286 0L2095 0L1905 0L1714 0L1524 0L1333 0L1143 0L952 0L762 0L571 0L381 0L190 0L0 0Z" fill="#426b78"></path>
    <path d="M0 85L190 93L381 154L571 102L762 106L952 107L1143 69L1333 201L1524 189L1714 111L1905 92L2095 152L2286 113L2476 126L2667 70L2857 199L3048 157L3238 99L3429 96L3619 116L3810 85L4000 177L4000 0L3810 0L3619 0L3429 0L3238 0L3048 0L2857 0L2667 0L2476 0L2286 0L2095 0L1905 0L1714 0L1524 0L1333 0L1143 0L952 0L762 0L571 0L381 0L190 0L0 0Z" fill="#39606c"></path>
    <path d={`M0 49L190 ${119}L381 127L571 72L762 78L952 134L1143 30L1333 128L1524 132L1714 128L1905 46L2095 96L2286 78L2476 131L2667 122L2857 129L3048 73L3238 46L3429 78L3619 88L3810 79L4000 87L4000 0L3810 0L3619 0L3429 0L3238 0L3048 0L2857 0L2667 0L2476 0L2286 0L2095 0L1905 0L1714 0L1524 0L1333 0L1143 0L952 0L762 0L571 0L381 0L190 0L0 0Z`} fill="#305561"></path>
    </svg>
    return sv
  })

  useEffect(()=>{
    window.addEventListener('scroll', 
      e=>{
        
        if (!inMotion){
          
        }

        anime({targets:"#visual-background",translateY:-e.target.childNodes[1].scrollTop/5,easing:'spring(0.2,1,0.75,10)'});
      }
    )
  },[]) 
  
    return (
  <div className='App'>
  <div id="page">
    {sv}
    <header>
      <h1 className="display-4">Emeka Ariwodo</h1>
    </header>
   <AppBody/>
  </div>
  </div>)
}




function AppBody(props){
  const [notes,wordle,resume,about,home] = [["Notes",<Notes reRender = {()=>render(p=>p+1)}/>],['Wordle',<Wordle solution={wordleWord}/>],["Resume",<Resume/>],["Hi",<About/>],["Home",<Home/>]]
  const [renders,render] = useState(1)

  
  const [pageState,UpdateState] = useState(notes);
  if (pageState!=notes){
    killLines()
  }
  useEffect(x=>{document.title=pageState[0]},[pageState]);
  return (
          <>
            <div id="Nav-bar">
              <ul  id="Root-List-Items">
                <li onClick={()=>{UpdateState(home)}}>Home</li>
                <li onClick={()=>{UpdateState(resume)}}>Resume</li>
                <li onClick={()=>{UpdateState(about)}}>About</li>
                <li
                    className = "Dropdown">Apps
                    <ul>
                    <li onClick={()=>{UpdateState(notes)}}>
                      Notes App
                    </li>
                    <li onClick={()=>UpdateState(wordle)}>Wordle App</li>
                    </ul>
                </li>
              </ul>
            
            </div>
              {pageState[1]}
          </>
        );
}



export default App;
