import Note from './NoteElement.js';
import React, { useState, useMemo, useEffect, useRef } from "react";
import LeaderLine from "react-leader-line";
import { isCompositeComponent } from 'react-dom/test-utils';
import { anime } from 'react-anime';
if (!localStorage['AriNote']) {
    // If there is no air note instance, create one

    localStorage['AriNote'] = JSON.stringify({})
}


export class NoteObject{
    constructor(id,content){
        this.content = content
        this.id = id
    }

}



// Local storage alias. Do this when client opens the notes page
export const NoteObj = JSON.parse(localStorage['AriNote']);
export const lineObj = {}

export function killLines() {

    if (document.querySelector('.leader-line:last-of-type')) {

        Object.keys(lineObj).forEach((key) => {
            lineObj[key]['lineFunction'].remove()
            delete lineObj[key]
        })
    }
}
let lnon = "";
let linkedElements = [];

export function joinNotes(noteKeyA, noteKeyB) {
    NoteObj[noteKeyA]['lineRelationships'].push(`${noteKeyA}-${noteKeyB}`)
    NoteObj[noteKeyB]['lineRelationships'].push(`${noteKeyA}-${noteKeyB}`)
    linkedElements[0] = document.getElementById(noteKeyA)
    linkedElements[1] = document.getElementById(noteKeyB)

    lineObj[`${noteKeyA}-${noteKeyB}`] = {
        elementsHtml: linkedElements,
        elementKeys: [noteKeyA, noteKeyB],
        lineFunction: new LeaderLine(linkedElements[0], linkedElements[1], { showEffectName: 'draw', hide: true, color: 'black', path: 'magnet', endPlug: 'arrow3' }),
        get deletePrimer() {
            //gives back a tuple of element keys
            return this.elementKeys
        },
        deleteFunction(personalId, primedTuple, self) {
            // If you are in index 0, select index 1. 
            const foundPartner = personalId == primedTuple[0] ? primedTuple[1] : primedTuple[0]
            // I should not forget to refresh the entire note page
            /* Comparing this relationship in the partner's relationships
            and deleting this relationship in the partner's
            N^2 max time complexity*/
            NoteObj[foundPartner]['lineRelationships'] = NoteObj[foundPartner]['lineRelationships'].filter((comparison) => {
                return comparison != self
            })
            saveStateLocal()
            lineObj[self]['lineFunction'].remove()
            delete lineObj[self]
        }
        /*
        How the delete function works:
        - One line relationship/object is marked for deletion along with an note element Id/obj
        - It takes the name of both elements involved in the deletion, connected/referenced by the line relationship
        - Gets the name of the element that isnt being deleted. Goes into the element's attributes
        - deletes the line relationship in those attributes
        -goes back into the original line object, delete the line SVG (functional|API), deletes the line object
        Example:
        //this function is called by element A1. A1 feeds its id into the function
        A1:
                lineObj[A1-id].deleteFunction(A1-id) -> 
                    deleteFunction(A1-id){
                    }

        */
    }

    document.querySelector('.leader-line:last-of-type').style.zIndex = 123;
    lineObj[`${noteKeyA}-${noteKeyB}`]['lineFunction'].show('draw')
    linkedElements = []
}
/*
noteObj stores the unique name
lineobj[uniqueName]={
    elements(actual):
    elementKeys: Element.id
    lineFunction: Object
}

 NoteObj[lnon] = {
            content:textbox,
            position:{  top:Math.random()*boundedClient['height']*0.9,
                        left:Math.random()*boundedClient['width']*0.9
                    },
            uniqueId:lnon,
            parent:[],
            lineRelationships:[],
            dragMemory:[]
        }
*/

// Last note that was included. Just incase I want to implement an undo funciton


export function saveStateLocal() {
    localStorage['AriNote'] = JSON.stringify(NoteObj)
}



function NoteInput(props) {

    const [doOnce,changeStateOnce] = useState(true)
    const [boundedClient, changeBC] = useState(null)

    if (!boundedClient) {
        props.host.current ? changeBC(props.host.current.getBoundingClientRect()) : console.log('Host boundary not rendered yet')
    }
    const [textbox, changetext] = useState('')
    const [nextLine, changenl] = useState(false)

    const [ev, _] = useState(

        () => {

            addEventListener('keydown', (e) => {
                e.code == 'Enter' ? changenl(true) : null

                
            }
            )
        }
    )
     
    
    

    if(props.editing.editing){
        if (doOnce){
            changeStateOnce(false)
            changetext(NoteObj[props.editing.beingEdited]['content'])
        }
    }


    if (props.editing.editing && nextLine){
        let hostReference = document.getElementById(props.editing.beingEdited)
        anime({
            targets: hostReference,
            opacity:1,
            duration:1000
        })
        props.editing.disableEditing('')
        NoteObj[props.editing.beingEdited]['content'] = textbox
        changenl(false)
        changetext('')
        props.reRender()
        saveStateLocal()
        props.updateNoteCount()
        setTimeout(()=>changeStateOnce(true),700)
        console.log('done editing',props.editing.beingEdited)
    }

    if ((nextLine && textbox.length > 0 && textbox != ' ') && !props.editing.editing) {
        
        changenl(false)

        lnon = Date.now().toString() + Math.floor(Math.random() * 5327)

        NoteObj[lnon] = {
            content: textbox,
            position: {
                top: Math.random() * boundedClient['height'] * 0.9,
                left: Math.random() * boundedClient['width'] * 0.9
            },
            uniqueId: lnon,
            parent: [],
            lineRelationships: [],
            dragMemory: []

        }
        // All notes are now automatically saved to local storage
        saveStateLocal()
        changetext('')
        props.reRender()
        props.updateNoteCount()
    }

    return <div id='post-widget'>
        <input type='text'
            autoComplete="off"
            id='input'
            value={textbox}
            onInput={(e) => {
                changetext(e.target.value)
            }}>
        </input>

        <span
            className='generic-button'

            onClick={
                () => {
                    delete localStorage['AriNote'];
                    for (let key in NoteObj) {
                        delete NoteObj[key];
                    }
                    props.reRender()
                }
            }>Clear
        </span>
        <span
            className='generic-button'
            onClick={

                () => {


                    // Delete the line relationships in each element and delete the actual lines
                    Object.keys(NoteObj).forEach((noteKey) => {
                        NoteObj[noteKey]['lineRelationships'].forEach(lineKey => {
                            if (lineObj.hasOwnProperty(lineKey)) {

                                lineObj[lineKey]['lineFunction'].remove()
                            }

                            delete lineObj[lineKey]
                        })

                        NoteObj[noteKey]['lineRelationships'] = []
                    })



                }}>
            Clear Links
        </span>
        

    </div>
}





function Canvas(props) {
    const [editState, updateEditing] = useState([false,'']) // contains the value of editing as well as the element ID beig edited 
    // Render state

    const [menuOpen, chng] = useState(false);
    const [lastMoved, lastMovedUpdate] = useState(null)
    const [noteCount, updateNoteCount] = useState(Object.keys(NoteObj).length)
    const [renderCount, renderCanvas] = useState(props.notesRenders)


    const canvasRef = props.parentCanvas.current ? [props.parentCanvas.current.getBoundingClientRect()['left'], props.parentCanvas.current.getBoundingClientRect()['top']] : [0, 0]
    // Only rerender when a new object is created.
    const notes = Object.keys(NoteObj).map((value) => <Note
        editing={{
            editing:editState[0],
            disableEditing:(myID)=>updateEditing([false,myID]),
            enableEditing:(myID)=>updateEditing([true,myID])
        }}

        value={value}
        menuStatus={{
            changeMenu: (x) => chng(x),
            menuValue: menuOpen
        }}
        rereRender={() => props.reRender()}
        reRender={() => renderCanvas(p => p + 1)}
        canvasRef={canvasRef}
        alertMoved={(v) => lastMovedUpdate(v)} // Sends itself into the child so the child just calls Fn
        alertDeletedElement={() => updateNoteCount(p => p - 1)} // For note deletion
    />
    )
    // Only runs when a note is moved or a new note is added or destroyed
    return (
        <>
            <NoteInput host={props.parentCanvas}
                editing={{
                    
                    editing:editState[0],
                    disableEditing:(myID)=>updateEditing([false,myID]),
                    enableEditing:(myID)=>updateEditing([true,myID]),
                    beingEdited:editState[1]
                }}
                reRender={() => renderCanvas(p => p + 1)}
                updateNoteCount={() => updateNoteCount(p => p + 1)} />
            <div id='note-mapper'>
                {notes}
            </div>
        </>
    )
}


function Notes(props) {
    // Render state.
    const [renderCount, countUpRender] = useState(0);

    useEffect(() => {
        // There is a bug that disconnects the remaining elements
        Object.keys(lineObj).forEach((key) => {
            //onsole.log(typeof( ),typeof( NoteObj[key]['elementsHtml'][1] ))

            if (lineObj[key]['elementsHtml'][0] != document.getElementById(lineObj[key]['elementKeys'][0]) || lineObj[key]['elementsHtml'][1] != document.getElementById(lineObj[key]['elementKeys'][1])) {
                console.log(`Had to redraw ${key}`)
                lineObj[key]['elementsHtml'][0] = document.getElementById(lineObj[key]['elementKeys'][0])
                lineObj[key]['elementsHtml'][1] = document.getElementById(lineObj[key]['elementKeys'][1])
                lineObj[key]['lineFunction'].remove()
                lineObj[key]['lineFunction'] = new LeaderLine(document.getElementById(lineObj[key]['elementKeys'][0]), document.getElementById(lineObj[key]['elementKeys'][1]), { color: 'black', path: 'magnet', endPlug: 'arrow3' })
                document.querySelector('.leader-line:last-of-type').style.zIndex = 123;
            }

        })
    }, [renderCount])

    useEffect(() => {

        for (const el of document.getElementsByClassName('leader-line')){
            // Clear up any ghost lines that appear on weird rerender

        }
        let x = ""

        for (x of Object.keys(NoteObj)) {
            if (NoteObj[x]['lineRelationships']) {

                NoteObj[x]['lineRelationships'].forEach(e => {
                    const [elementAId, elementBId] = e.split('-')
                    const elementA = document.getElementById(elementAId)
                    const elementB = document.getElementById(elementBId)

                    if (!lineObj[e]) {

                        lineObj[e] = {
                            elementsHtml: [elementA, elementB],
                            elementKeys: [elementAId, elementBId],
                            lineFunction: new LeaderLine(elementA, elementB, { color: 'black', path: 'magnet', endPlug: 'arrow3'}),

                            get deletePrimer() {
                                //gives back a tuple of element keys
                                return this.elementKeys
                            },

                            deleteFunction(personalId, primedTuple, self) {
                                // If you are in index 0, select index 1. 
                                const foundPartner = personalId == primedTuple[0] ? primedTuple[1] : primedTuple[0]
                                // I should not forget to refresh the entire note page
                                /* Comparing this relationship in the partner's relationships
                                and deleting this relationship in the partner's
                                N^2 max time complexity*/
                                NoteObj[foundPartner]['lineRelationships'] = NoteObj[foundPartner]['lineRelationships'].filter((comparison) => comparison != self)
                                saveStateLocal()
                                lineObj[self]['lineFunction'].remove()
                                delete lineObj[self]
                            }
                        }
                        document.querySelector('.leader-line:last-of-type').style.zIndex = 123;
                    }
                })
                // Given the above IDs, generate a line from elementA to elementB


            }
        }

        // First rerender to make sure ref hook is pointing to the correct element
        countUpRender(p => p + 1)
    }, [])

    const canvasRef = useRef(null)



    return (
        <div
            id='webContent'
            ref={canvasRef}>
            <Canvas
                parentCanvas={canvasRef}
                reRender={() => countUpRender(p => p + 1)}
                notesRenders={renderCount} />
        </div>


    )
}


export default Notes