/*
Note menu should do the following:
- Display edit button
- Display option to delete note ties
    - X that highlights the element
- Display option to 
- export modular list 
*/


import { useEffect, useState } from "react"
import { anime } from "react-anime"
import LeaderLine from "react-leader-line"
import { closeWindow } from "./NoteElement"
import { lineObj, NoteObj, joinNotes, saveStateLocal} from "./Notes2"







function selectBehavior(e, hostID, avoid,close) {
    
    // You are selecting notes for pairing
    if (!Number(e.target.parentNode.id)) {
        // Return null if the selected element doesnt have an ID that is numeric. Attribute unique to note elements
        return
    }
    if (avoid.includes(e.target.parentNode.id)) {
        // If you are selecting anything that is inside the "avoid" list.
        // Contains already-made pairs and the element that is doing the pairing
        return  
    }
    
        // If this 
    // Ensure that the note element FOR SURE cannot link to itself
    if (e.target.parentNode.id != hostID) {
        joinNotes(hostID, e.target.parentNode.id)
        close()
    }
    
}

export default function NoteMenu(props) {
    const hostReference = document.getElementById(props.host)

    // Note menu function. Holds the menu that pops up
    const [renders, refreshState] = useState(0)

    const lineRelationships = Object.keys(lineObj).filter((pairs) => {
        return pairs.split('-').includes(props.host)
    }).map((pairsM) => {
        return pairsM.split('-').filter((x) => x != props.host)[0]
    })

    lineRelationships.push(props.host)


    // Available notes has everything that is not in
    const availableNotes = Object.keys(NoteObj).filter((value) => !lineRelationships.includes(value))

    // 
    const linker = availableNotes.length>0?(
                <p
                    onTouchStart={(e) => {
                        window.addEventListener('touchstart', function touchBehavior(e) {
                            // Modified version of the click linker function for mobile.
                            setTimeout(() => {
                                window.removeEventListener('touchstart', touchBehavior)
                            }, 2000);

                            if (lineRelationships.includes(e.target.parentNode.id)) {
                                return
                            }

                            if (!Number(e.target.parentNode.id)) {
                                return
                            }

                            if (e.target.parentNode.id != props.host) {

                                joinNotes(props.host, e.target.parentNode.id)

                                window.removeEventListener('touchstart', touchBehavior)
                                props.closeSelf()

                            } if (avoid.includes(e.target.parentNode.id)) {
                                return
                            }

                        })
                        availableNotes.map(
                            (name, place) => {
                                // Animate 'bouncing' of available elements
                                anime({
                                    targets: document.getElementById(name),
                                    delay: 100 * place,
                                    translateY: ['15px', '0px']
                                })
                                document.querySelector('.leader-line:last-of-type').style.zIndex = 123})

                        // Draw the elements that were creaed in the last 
                        NoteObj[props.host]['toBeCleared'].forEach(element => {
                            element.show('draw')
                        });

                        // Async delete all elements after 2000 seconds
                        // Todo: Change this so that It awaits a selection. 
                    }
                    }

                    onClick={(e) => {
                        // Event listener for selecting a note to link to
                        window.addEventListener('mousedown', e => selectBehavior(e, props.host, lineRelationships,props.closeSelf()), { once: true })
                        // Make the notes bounce
                        availableNotes.map(
                            (name, place) => {
                                anime({
                                    targets: document.getElementById(name),
                                    delay: 150 * place,
                                    translateY: ['15px', '0px']
                                })
                                NoteObj[props.host]['toBeCleared'][place]? setTimeout(x => NoteObj[props.host]['toBeCleared'][place].show('draw'), 200 * place):null
                                //document.querySelector('.leader-line:last-of-type').style.zIndex = 123
                            }

                        )
                        // Change to be async
                        

                    }}>⇥</p>
                    ):<p>-</p>

    const deleteLink = (
                <p
                onClick={()=>{
                    NoteObj[props.host]['lineRelationships'].forEach((keyz) => {
                        // Loop through relationships and delete the common link in the neighbors
                        // Keyz format: parent-child
                        lineObj[keyz].lineFunction.hide('draw')
                        
                        setTimeout(()=>lineObj[keyz].deleteFunction(props.host, lineObj[keyz].deletePrimer, keyz),1000)
                        

                    })
                    
                    NoteObj[props.host]['lineRelationships'] = []
                    props.closeSelf()
                }}

                onTouchStart={()=>{
                    NoteObj[props.host]['lineRelationships'].forEach((keyz) => {
                        // Loop through relationships and delete the common link in the neighbors
                        // Keyz format: parent-child
                        lineObj[keyz].lineFunction.hide('draw')
            
                        setTimeout(()=>{
                            lineObj[keyz].deleteFunction(props.host, lineObj[keyz].deletePrimer, keyz)
                            
                        },1000)
                        
                    })
                    
                    NoteObj[props.host]['lineRelationships'] = []
                    props.closeSelf()
                }}>⇏</p>
    )

    const EDIT_BUTTON =( 
        <p
        onClick={
            ()=>{
                if (!props.editing.editing){
                    props.editing.enableEditing(props.host)
                    NoteObj[props.host]['lineRelationships'].forEach((keyz) => {
                        // Hide the line
                        lineObj[keyz].lineFunction.hide('draw')
                        // Set a timeout to delete the line reference from the connected note
                        setTimeout(()=>{
                            lineObj[keyz].deleteFunction(props.host, lineObj[keyz].deletePrimer, keyz)
                        },1000)
                    })
                    // Erase all lines in this note
                    NoteObj[props.host]['lineRelationships'] = []
                    // 
                    anime({
                        targets: hostReference,
                        opacity:0,
                        duration:500
                    })
                }

                


                props.closeSelf()
                
            }
        }

        onTouchStart={
            ()=>{

                if (!props.editing.editing){
                    props.editing.enableEditing(props.host)
                    NoteObj[props.host]['lineRelationships'].forEach((keyz) => {
                        // Hide the line
                        lineObj[keyz].lineFunction.hide('draw')
                        // Set a timeout to delete the line reference from the connected note
                        setTimeout(()=>{
                            lineObj[keyz].deleteFunction(props.host, lineObj[keyz].deletePrimer, keyz)
                        },1000)
                    })
                    // Erase all lines in this note
                    NoteObj[props.host]['lineRelationships'] = []
                    anime({
                        targets: hostReference,
                        opacity:0,
                        duration:500
                    })
                }
                
                props.closeSelf()

            }
        }
        >✎</p>
)
    
    const noteclass = `NoteMenu ${"p" + props.host}`
    const output = (
        <div

            className={noteclass}
            style={{ position: 'absolute'}}>
            {linker}
            {deleteLink}
            {EDIT_BUTTON}
        </div>)

    useEffect(() => {
        anime({ targets: `${".p" + props.host}`, top: '-4em', left: ['50px', '110%'], duration: 400, scale: [0.1, 1.05] })
    })
    return output
}