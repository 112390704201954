
import React, { useState, useRef, useEffect } from 'react'
import { lineObj, NoteObj, saveStateLocal } from './Notes2.js'
import { anime } from 'react-anime'
import Draggable from 'react-draggable'
import NoteMenu from './NoteMenu.js'




function Note(props) {

    const noteReference = useRef(null)
    const [noteMenuDisplay, changeNMD] = useState('')
    useEffect(
        () => {
            if (noteMenuDisplay != '') {
                props.menuStatus.changeMenu()
            }
        }, [noteMenuDisplay])

    
    return (

        <Draggable
            position={{ x: 0, y: 0 }}
            onDrag={
                () => {
                    if (NoteObj[props.value]['lineRelationships']) {
                        NoteObj[props.value]['lineRelationships'].forEach((x) => {
                            lineObj[x]['lineFunction'].position()
                        })
                    }
                }
            }

            onStop={(e, data) => {
                // Optimize this late with a for loop

                NoteObj[props.value]['position'] = {
                    left: data.x + NoteObj[props.value]['position']['left'],
                    top: data.y + NoteObj[props.value]['position']['top']
                }
                // Saving NoteObj
                saveStateLocal()
                // Render
                props.rereRender()
                // Updated the last clicked element state
                props.alertMoved(props.value)
            }}>

            <span
                id={props.value}
                ref={noteReference}
                className='list-item'
                key={props.value}
                style={NoteObj[props.value]['position']}>
                <div
                    id="note-menu">

                    <div
                        className="menu-icon"
                        onTouchStart={
                            () => {

                                NoteObj[props.value]['lineRelationships'].forEach((keyz) => {
                                    // In each of these I get the key for the lineObjects contained by the now d
                                    // deleted object

                                    lineObj[keyz].deleteFunction(props.value, lineObj[keyz].deletePrimer, keyz)
                                })
                                delete NoteObj[props.value]
                                saveStateLocal()
                                props.rereRender()
                            }}
                        onClick={
                            () => {

                                NoteObj[props.value]['lineRelationships'].forEach((keyz) => {
                                    // In each of these I get the key for the lineObjects contained by the now d
                                    // deleted object
                                    lineObj[keyz].deleteFunction(props.value, lineObj[keyz].deletePrimer, keyz)

                                })
                                delete NoteObj[props.value];
                                saveStateLocal()
                                props.rereRender()
                            }}>
                        ✗
                    </div>

                    <div className="menu-icon"
                        /*
                        Menu open: True
                        notemenudisplay does not have content in it: True (default false)
                        */
                        onTouchStart={(e) => {
                            if (props.menuStatus.menuValue && noteMenuDisplay) {

                            } else if (noteMenuDisplay) {
                                // Menuvalue is true but nothing is displaying. 
                                // Assert that if the note menu is being closed, the host value 
                                closeWindow(changeNMD, props.value)
                            } else if (!props.menuStatus.menuValue && !noteMenuDisplay) {
                                changeNMD(
                                    <NoteMenu
                                        editing = {props.editing}
                                        closeSelf={() => closeWindow(changeNMD, props.value)}
                                        eventProperties={e}
                                        host={props.value} />)
                            }
                        }}

                        onClick={(e) => {
                            
                            if (props.menuStatus.menuValue && !noteMenuDisplay) {


                            } else if (noteMenuDisplay) {
                                // Menuvalue is true but nothing is displaying. 
                                // Assert that if the note menu is being closed, the host value 
                                closeWindow(changeNMD, props.value)
                            } else {
                                changeNMD(
                                    <NoteMenu
                                        editing = {props.editing}
                                        closeSelf={() => closeWindow(changeNMD, props.value)}
                                        eventProperties={e}
                                        host={props.value} />)
                            }
                        }}


                    >≡</div>
                    {noteMenuDisplay}
                </div>
                <p>
                    {NoteObj[props.value]['content']}
                </p>
            </span>
        </Draggable>
    )

}
export function closeWindow(changeMenuValue, classProp) {
    // Animate the menu falling into the burger bar
    anime({ targets: `${".p" + classProp}`, left: '68%', top: 0, duration: 200, easing: 'linear', scale: [1.5, 0] })
    // 


    setTimeout(
        () => {
            changeMenuValue('')

        }, 300)
}

export default Note